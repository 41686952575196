import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Button,
  Box,
  Image,
  Section,
  Span,
  List,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Gear & Grind Rentals</title>
        <meta
          name={"description"}
          content={"Where Adventure Begins on Two Wheels"}
        />
        <meta property={"og:title"} content={"Home | Gear & Grind Rentals"} />
        <meta
          property={"og:description"}
          content={"Where Adventure Begins on Two Wheels"}
        />
        <meta
          property={"og:image"}
          content={"https://rovenabet.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://rovenabet.com/img/favicon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://rovenabet.com/img/favicon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://rovenabet.com/img/favicon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" quarkly-title="Hero-16">
        <Override slot="SectionContent" flex-direction="row" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            lg-width="100%"
            display="flex"
            flex-direction="column"
            justify-content="center"
            padding="0px 80px 0px 0px"
            lg-padding="0px 0 0px 0px"
            lg-margin="0px 0px 50px 0px"
            align-items="flex-start"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 700 56px/1.2 --fontFamily-sans"
              sm-font="normal 700 42px/1.2 --fontFamily-sans"
            >
              Gear & Grind Rentals
            </Text>
            <Text margin="0px 0px 50px 0px" font="--base" color="#8b9197">
              Welcome, adventurers and thrill-seekers! At Gear & Grind Rentals,
              we believe every journey starts with a great ride. Whether you're
              cruising through city streets or exploring rugged landscapes, our
              top-notch motorbikes are your reliable companion for unforgettable
              experiences. Dive into the thrill of the ride with us – where your
              adventure begins.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
              sm-align-self="stretch"
            >
              <Button
                margin="0px 15px 0px 0px"
                padding="12px 28px 12px 28px"
                background="--color-primary"
                border-radius="8px"
                font="normal 400 17px/1.5 --fontFamily-sans"
                sm-margin="0px 0 15px 0px"
                transition="background-color 0.2s ease 0s"
                border-width="2px"
                border-style="solid"
                border-color="--color-primary"
                hover-color="--darkL1"
                hover-background="rgba(63, 36, 216, 0)"
                sm-width="100%"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
              >
                Contact Us
              </Button>
            </Box>
          </Box>
          <Image
            src="https://rovenabet.com/img/1.jpg"
            display="block"
            width="50%"
            object-fit="cover"
            height="100%"
            lg-width="100%"
            lg-height="700px"
            sm-height="500px"
          />
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
        />
        <Box
          width="50%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
          padding="0px 0px 0px 0px"
          lg-padding="0px 30px 0px 0px"
          md-padding="0px 0px 0px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Image
            max-width="500px"
            src="https://rovenabet.com/img/2.jpg"
            md-margin="0px 0px 20px 0px"
          />
        </Box>
        <Box
          width="50%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
        >
          <Text
            margin="0px 0px 40px 0px"
            color="--dark"
            font="--headline2"
            md-margin="0px 0px 30px 0px"
          >
            At Gear & Grind, we're more than just a rental service – we're your
            gateway to new adventures.
          </Text>
          <Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
            Our fleet consists of meticulously maintained motorcycles suited for
            any terrain or preference. From sleek city cruisers to robust
            off-road bikes, we offer a variety of options to match your spirit
            of exploration. Choose us for:
          </Text>
        </Box>
      </Section>
      <Section
        padding="90px 0 90px 0"
        sm-padding="40px 0"
        min-height="600px"
        sm-min-height="auto"
        display="flex"
        quarkly-title="Advantages/Features-19"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          grid-gap="5%"
          sm-min-width="280px"
        />
        <Box
          display="flex"
          width="45%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-width="100%"
          lg-align-items="flex-start"
          lg-margin="0px 0px 60px 0px"
          sm-margin="0px 0px 40px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="24px 0px 16px 0px"
          lg-flex-direction="column"
          lg-flex-wrap="no-wrap"
          sm-width="100%"
          md-margin="0px 0px 30px 0px"
        >
          <List
            margin="40px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            as="ul"
            sm-margin="24px 0 0 0"
            list-style-type="none"
            font="normal normal 18px/150% sans-serif"
            display="flex"
            flex-direction="column"
            grid-gap="25px"
            lg-margin="32px 0px 0px 0px"
          >
          <Text margin="0px 0px 0px 0px" color="--darkL2" font="normal 700 42px/1.2 --fontFamily-sans">
            Why Ride with Us?
          </Text>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                color="--greyD2"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--darkL2"
                >
                  Unmatched Quality: Every bike in our fleet undergoes rigorous
                  checks and regular maintenance to ensure your safety and
                  comfort.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                color="--darkL2"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--darkL2"
                >
                  Flexible Rentals: Whether you need a ride for a day, a week,
                  or longer, we've got you covered with flexible rental plans
                  tailored to your journey.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                color="--darkL2"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--darkL2"
                >
                  Competitive Pricing: Enjoy the best rates in the market
                  without compromising on quality or service. More adventure,
                  less spending.
                </Span>
              </Text>
            </Box>
            <Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
              <Text
                padding="0"
                margin="0"
                font="18px/27px --fontFamily-sans"
                lg-max-width="720px"
              >
                <Span
                  opacity="0.9"
                  overflow-wrap="normal"
                  word-break="normal"
                  white-space="normal"
                  text-indent="0"
                  text-overflow="clip"
                  hyphens="manual"
                  user-select="auto"
                  pointer-events="auto"
                  font="--base"
                  color="--darkL2"
                >
                  Personalized Routes: Get complimentary access to our curated
                  routes. Discover hidden gems and the road less traveled, all
                  at your own pace.
                </Span>
              </Text>
            </Box>
          </List>
        </Box>
        <Box
          display="flex"
          width="50%"
          justify-content="flex-start"
          lg-justify-content="center"
          lg-width="100%"
          padding="0px 0px 0px 16px"
          align-items="center"
          lg-padding="0px 0px 0px 0px"
          font="20px sans-serif"
        >
          <Box
            width="972px"
            height="589px"
            md-display="flex"
            md-justify-content="center"
            sm-height="300px"
            lg-height="auto"
          >
            <Image
              box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
              src="https://rovenabet.com/img/3.jpg"
              height="589px"
              lg-width="100%"
              width="972px"
              border-radius="8px"
              z-index="9"
              position="relative"
              lg-margin="0px 0px 0px 0px"
              sm-height="300px"
              object-fit="cover"
              lg-height="auto"
              lg-display="block"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
